import { nextTick } from "process";
import { defineComponent, onActivated, onMounted, reactive, toRefs } from "vue";
import CompanyProfile from "./components/companyProfile.vue";
import CoreAdvant from "./components/coreAdvant.vue";
import JoinUs from "./components/joinUs.vue";
import NewsCenter from "./components/newsCenter.vue";
import { basicMixin } from "@/mixin/basicMixin";
import { getGeneralInfo } from "@/http/about";
import { INTERFACE_TYPE_ENUM } from "@/utils/constant";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  components: {
    CompanyProfile,
    CoreAdvant,
    JoinUs,
    NewsCenter
  },
  mixins: [basicMixin],

  setup() {
    const router = useRouter();
    const route = useRoute();

    const _this = reactive({
      // 轮播图数据
      companyData: {},
      CoreAdv: [],
      flag: false
    });

    const methods = {
      async getData() {
        const res = await getGeneralInfo({
          orgCode: sessionStorage.getItem("orgCode") || "",
          typeCode: 0
        });

        if (res.data.code == INTERFACE_TYPE_ENUM.SUCCESS) {
          _this.companyData = res.data.data;

          if (route.query.flag) {
            nextTick(() => {
              let timer = setTimeout(() => {
                var _document$getElementB;

                (_document$getElementB = document.getElementById("news")) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.scrollIntoView({
                  behavior: "smooth",
                  block: "start"
                });
                clearTimeout(timer);
              }, 500);
            });
          }
        } else {
          ElMessage.error(res.data.message);
        }
      }

    };
    onMounted(() => {
      methods.getData();
    });
    onActivated(() => {
      // 跳转至新闻中心模块
      basicMixin.methods.toModuleLocation();
    });
    return { ...toRefs(_this)
    };
  }

});