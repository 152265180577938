import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CompanyProfile = _resolveComponent("CompanyProfile");

  const _component_CoreAdvant = _resolveComponent("CoreAdvant");

  const _component_JoinUs = _resolveComponent("JoinUs");

  const _component_NewsCenter = _resolveComponent("NewsCenter");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_CompanyProfile), _createVNode(_component_CoreAdvant, {
    companyData: _ctx.companyData
  }, null, 8, ["companyData"]), _createVNode(_component_JoinUs), _createVNode(_component_NewsCenter, {
    id: "news"
  })]);
}