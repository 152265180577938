import { defineComponent, reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  props: {
    companyData: {
      default: []
    }
  },

  setup(props) {
    const router = useRouter();

    const _this = reactive({
      CoreAdv: [],
      flag: false
    });

    watch(() => props.companyData, (newValue, oldValue) => {
      if (newValue) {
        _this.CoreAdv = JSON.parse(props.companyData.infos);

        _this.CoreAdv.forEach(ele => {
          if (ele.content.length > 120) {
            _this.flag = true;
          }
        });
      }
    });
    return {
      router,
      ...toRefs(_this)
    };
  }

});