import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getGeneralInfo } from "@/http/about";
import { INTERFACE_TYPE_ENUM } from "@/utils/constant";
import { ElMessage } from "element-plus";
import { getPartPage } from "@/http/home";
export default defineComponent({
  setup() {
    const router = useRouter();

    const _this = reactive({
      companyData: {},
      flag: false,
      name: "",
      subtitle: ""
    });

    const methods = {
      async getData() {
        const res = await getGeneralInfo({
          orgCode: sessionStorage.getItem("orgCode") || "",
          typeCode: 1
        });

        if (res.data.code == INTERFACE_TYPE_ENUM.SUCCESS) {
          _this.companyData = res.data.data;
        } else {
          ElMessage.error(res.data.message);
        }
      },

      // 获取栏目列表
      getPartPageList() {
        getPartPage({
          orgCode: sessionStorage.getItem("orgCode") || "",
          listCode: 3,
          location: 1
        }).then(async res => {
          if (res.data.code == 200) {
            res.data.data.forEach(ele => {
              if (ele.typeCode == "inbuilt") {
                if (ele.link == "join") {
                  _this.flag = true;
                  _this.name = ele.name;
                  _this.subtitle = ele.subtitle;
                }
              }

              if (ele.children) {
                ele.children.forEach(element => {
                  if (element.typeCode == "inbuilt") {
                    if (element.link == "join") {
                      _this.flag = true;
                      _this.name = element.name;
                      _this.subtitle = element.subtitle;
                    }
                  }
                });
              }
            });
          }
        });
      },

      joinUs() {
        const {
          name,
          subtitle
        } = _this;
        router.push(`hr?onlyName=${name}&subtitle=${subtitle}`);
      }

    };
    onMounted(() => {
      methods.getData();
      methods.getPartPageList();
    });
    return {
      router,
      ...toRefs(_this),
      ...methods
    };
  }

});