import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { getEnterpriseDynamic } from "@/http/about";
import { ElMessage } from "element-plus";
import { imgUrl } from "@/utils/urlManage";
import moment from "moment";
import { basicMixin } from "@/mixin/basicMixin";
import { useRoute } from "vue-router";
export default defineComponent({
  mixins: [basicMixin],

  setup() {
    const route = useRoute();
    const data = reactive({
      num: route.query.typeNum ? route.query.typeNum : 0,
      // 企业动态新闻数据
      IndustryInformationList: [],
      pageNo: 1,
      pageSize: 5,
      total: 0
    });
    const methods = {
      /**
       * 获取新闻列表
       */
      getEnterpriseDynamicMethod() {
        getEnterpriseDynamic({
          pageNo: data.pageNo,
          pageSize: data.pageSize,
          typeCode: data.num,
          orgCode: sessionStorage.getItem("orgCode") || ""
        }).then(res => {
          if (res.data.code == 200) {
            data.IndustryInformationList = res.data.data.data;
            data.total = res.data.data.total;
          } else {
            ElMessage.error(res.statusText);
          }
        });
      },

      /**
       * 切换企业动态、行业新闻
       * @param num 切换的索引值
       */
      changeNum(num) {
        data.pageNo = 1;
        data.num = num;
        methods.getEnterpriseDynamicMethod();
      },

      // 切换分页大小
      handleSizeChange(val) {
        data.pageSize = val;
        methods.getEnterpriseDynamicMethod();
      },

      // 切换页码时触发
      handleCurrentChange(val) {
        data.pageNo = val;
        methods.getEnterpriseDynamicMethod();
      }

    };
    onMounted(() => {
      methods.getEnterpriseDynamicMethod();
    });
    return { ...toRefs(data),
      imgUrl,
      moment,
      ...methods
    };
  }

});